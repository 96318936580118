<template>
  <div class="home">
    <div class="content">
      <div style="width: 100%; max-width: 650px; margin-x: auto;">
        <b-row class="justify-content-between align-items-center">
          <b-col cols="auto">
            <router-link to="/">
              <img src="@/assets/images/back-arrow.png" alt="back" style="width:20px;height:auto;">
            </router-link>
          </b-col>
          <b-col cols="auto"><p class="mb-0" style="text-align: center;"><b>PREGUNTAS FRECUENTES</b></p></b-col>
          <b-col cols="auto"><div style="width:20px;"></div></b-col>
        </b-row>
        <div style="margin-bottom:5rem;"></div>
        <div v-for="(question, index) in questions" :key="index">
          <p class="mb-2"><b>{{ question.question }}</b></p>
          <p class="mb-3">{{ question.answer }}</p>
          <div v-if="index < questions.length - 1" class="divider mb-3" style="border-bottom: 2px solid white;width:100%; opacity:0.3;"></div>
        </div>
      </div>
    </div>
    <p class="contact mb-0">Contacto: <a href="mailto:hola@lyp.mx">hola@lyp.mx</a></p>
  </div>
</template>

<script>
export default {
  name: 'Gifts',
  data () {
    return {
      questions: [
        {
          "question": "¿Cómo debo ir vestido? ",
          "answer": "Formal y nos encantaría que usaras colores oscuros."
        },
        {
          "question": "¿Puedo llevar niños a la fiesta?",
          "answer": "No, esta vez la fiesta solo es para adultos. "
        },
        {
          "question": "¿Hay estacionamiento?",
          "answer": "Si, habrá Vallet Parking pero queremos que diviertas así que te recomendamos usar taxi (Uber, Didi, etc.)."
        },
        {
          "question": "¿Me recomiendan usar tacones? ",
          "answer": "Puedes hacerlo pero considera que el museo esta construido sobre una superficie de piedra volcánica."
        },
        {
          "question": "¿Habrá cena?",
          "answer": "Sí, considera que parte del platillo será carne. No hemos considerado un menú vegetariano o vegano."
        },
        {
          "question": "¿Qué le regalo a los novios?",
          "answer": "En la sección de Regalos, tienes dos opciones: nuestra mesa de regalos en el Palacio de Hierro o una wallet de USDT."
        },
        {
          "question": "¿Algo más que deba saber? ",
          "answer": "Queremos que vengas a festejar con nosotros el amor, así que te esperamos con muy buena actitud y energía chida."
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/bootstrap.scss';
  .home {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .content {
      position: relative;
      z-index: 1;
      width: 100%;
      min-height: calc(100% - 69.6px);
      display: flex;
      align-items: center;
      flex-flow: column;

      padding: 3rem;
    }

    .contact {
      position: relative;
      display: block;
      margin: 0;
      padding-bottom: 3rem;
      text-align: center;
      z-index: 1;

      a {
        color: white !important;
      }
    }
  }
</style>
