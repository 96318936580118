<template>
  <div class="home">
    <img class="background d-none d-md-block" src="@/assets/images/background.png" alt="background" @load="onBackgroundLoaded">
    <div v-if="showLoader" class="loader" id="loader">
      <img id="logo" class="logo animation-fadeInOut" src="@/assets/images/logo.svg" alt="logo">
    </div>
    <div class="content">
      <img class="logo mb-5" src="@/assets/images/logo.svg" alt="logo">
      <p class="mb-2">6 MAYO 2023</p>
      <p class="mb-5">MUSEO ANAHUACALLI, CDMX</p>
      <b-button variant="outline-light" style="width:300px;max-width:100%;font-size:.7rem;" class="py-3 mb-4" href="https://www.eventbrite.com.mx/e/lyp-tickets-534674996307">CONFIRMAR ASISTENCIA</b-button>
      <b-button variant="outline-light" style="width:300px;max-width:100%;font-size:.7rem;" class="py-3 mb-4" :to="{name: 'gifts'}">MESA DE REGALOS</b-button>
      <b-button variant="outline-light" style="width:300px;max-width:100%;font-size:.7rem;" class="py-3 mb-0" :to="{name: 'faqs'}">PREGUNTAS FRECUENTES</b-button>
    </div>
    <p class="contact mb-0">Contacto: <a href="mailto:hola@lyp.mx">hola@lyp.mx</a></p>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {
      showLoader: true
    }
  },
  created () {
    // wait max 5s to remove loader
    setTimeout(() => {
      this.onBackgroundLoaded()
    }, 5000);
  },
  methods: {
    onBackgroundLoaded () {
      if (this.showLoader) {
        setTimeout(() => {
          document.getElementById('logo').classList.remove('animation-fadeInOut')
          document.getElementById('loader').classList.add('animation-fadeOut')
          setTimeout(() => {
            this.showLoader = false;
          }, 2000)
        }, 1000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/bootstrap.scss';
  .home {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    img.background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      object-position: center;
      scale: 1 120%;
    }

    .loader {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: $black;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;

      .logo {
        height: auto;
        width: 15%;
        max-width: 180px;
        @media screen and (max-width: 425px) {
          width: 25%;
        }
      }
    }

    .content {
      position: relative;
      z-index: 1;
      width: 100%;
      min-height: calc(100% - 69.6px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;

      padding: 3rem;

      .logo {
        display: block;
        height: auto;
        width: 20%;
        max-width: 180px;
        @media screen and (max-width: 425px) {
          width: 50%;
        }
      }
    }

    .contact {
      position: relative;
      display: block;
      margin: 0;
      padding-bottom: 3rem;
      text-align: center;
      z-index: 1;

      a {
        color: white !important;
      }
    }
  }

  @keyframes fadeInOut {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  .animation-fadeInOut {
    animation-name: fadeInOut;
    animation-duration: 1.7s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: ease-in-out;
    animation-delay: 1.7s;
    animation-fill-mode: initial;
  }

  .animation-fadeOut {
    animation-name: fadeOut;
    animation-duration: 1.7s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
</style>
