import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Gifts from '../views/Gifts.vue'
import FAQ from '../views/FAQ.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gifts',
    name: 'gifts',
    component: Gifts
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: FAQ
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    return { top: 0 }
  }
})

export default router
