<template>
  <div class="home">
    <div class="content">
      <div style="width: 100%; max-width: 650px; margin-x: auto;">
        <b-row class="justify-content-between align-items-center">
          <b-col cols="auto">
            <router-link to="/">
              <img src="@/assets/images/back-arrow.png" alt="back" style="width:20px;height:auto;">
            </router-link>
          </b-col>
          <b-col cols="auto"><p class="mb-0" style="text-align: center;"><b>MESA DE REGALOS</b></p></b-col>
          <b-col cols="auto"><div style="width:20px;"></div></b-col>
        </b-row>
        <div style="margin-bottom:5rem;"></div>

        <b-row class="mb-5 justify-content-between">
          <b-col class="text-center text-md-left mb-5 mb-md-0" cols="12" md="auto">Mesa de regalos Palacio de Hierro</b-col>
          <b-col class="text-center text-md-left" cols="12" md="auto">
            Evento: <a href="https://www.elpalaciodehierro.com/buscar?eventId=377640" target="_blank" style="color:white;text-decoration:underline;"><b>377640</b></a>
          </b-col>
        </b-row>

        <div class="divider mb-5" style="border-bottom: 2px solid white;width:100%; opacity:0.3;"></div>

        <b-row class="justify-content-between">
          <b-col class="text-center text-md-left mb-5 mb-md-0" cols="12" md="auto">Regalar USDT</b-col>
          <b-col class="text-center text-md-left" cols="12" md="auto">
            <p class="mb-2" style="word-break: break-word;">Wallet: <span><b>THWUPU7wPGKPb185Y4N6jVUjANbUzxR1e6</b></span></p>
            <p>Red: <b>Tron (TRC20)</b></p>
          </b-col>
        </b-row>
      </div>
    </div>
    <p class="contact mb-0">Contacto: <a href="mailto:hola@lyp.mx">hola@lyp.mx</a></p>
  </div>
</template>

<script>
export default {
  name: 'Gifts',
  data () {
    return {
      
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/bootstrap.scss';
  .home {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .content {
      position: relative;
      z-index: 1;
      width: 100%;
      min-height: calc(100% - 69.6px);
      display: flex;
      align-items: center;
      flex-flow: column;

      padding: 3rem;
    }

    .contact {
      position: relative;
      display: block;
      margin: 0;
      padding-bottom: 3rem;
      text-align: center;
      z-index: 1;

      a {
        color: white !important;
      }
    }
  }
</style>
